<template>
    <div>
        <Header title="Mensagens" addText="Mensagem" :addFields="addFields" :meta="meta"/>
        <b-table sticky-header="65vh" no-border-collapse hover :items="messages" :fields="fields"/>
    </div>
</template>

<script>

import Header from '@/components/Dashboard/Header'

import { createNamespacedHelpers } from 'vuex'

const mapState = createNamespacedHelpers('messages').mapState

export default {
  components: {
    Header
  },
  data: () => ({
    meta: {
      title: 'Mensagem',
      module: 'messages',
      state: 'messages',
      modes: {
        add: {
          title: 'Enviar Mensagem',
          success: 'Mensagem enviada com successo',
          urn: 'message/create',
          response: 'message',
          method: 'post'
        }
      }
    },
    fields: [
      { key: 'title', label: 'Título', sortable: true },
      { key: 'subject', label: 'Assunto', sortable: true },
      { key: 'content', label: 'Conteúdo', sortable: true }
    ],
    addFields: [
      { key: 'recive_ids', label: 'Grupo', type: 'select', multiple: true, module: 'groups', state: 'groups', options: { value: 'id', text: 'key' } },
      { key: 'title', label: 'Título', type: 'text' },
      { key: 'content', label: 'Conteúdo', type: 'textarea' },
      { key: 'priority', label: 'Prioridade', type: 'select', options: [{ value: '1', text: 'Urgente' }, { value: '2', text: 'Alta' }, { value: '3', text: 'Média' }, { value: '4', text: 'Normal' }, { value: '5', text: 'Baixa' }] },
      { key: 'send_id', type: 'hidden', value: { store: 'state.user.id' } }
    ]
  }),
  computed: mapState(['messages'])
}
</script>
